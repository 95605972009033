import { useEthers } from '@usedapp/core';
import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber, utils } from 'ethers';

export const useReverseENSLookUp = (address: string) => {
  const { library } = useEthers();
  const [ens, setEns] = useState<string>();

  useEffect(() => {
    let mounted = true;
    if (address && library) {
      lookupAddress(library, address)
        .then(name => {
          if (!name) return;
          if (mounted) {
            setEns(name);
          }
        })
        .catch(error => {
          console.log(`error resolving reverse ens lookup: `, error);
        });
    }

    return () => {
      setEns('');
      mounted = false;
    };
  }, [address, library]);

  return ens;
};

export async function lookupAddress(
  library: Web3Provider,
  address: string,
): Promise<string | null> {
  try {
    // Call resolver contract
    const res = await library.call({
      to: '0x5982ce3554b18a5cf02169049e81ec43bfb73961', // see https://etherscan.io/address/0x5982cE3554B18a5CF02169049e81ec43BFB73961
      data: '0x55ea6c47000000000000000000000000' + address.substring(2), // call .resolve(address) method
    });
    // Parse result into a string.
    const offset = BigNumber.from(utils.hexDataSlice(res, 0, 32)).toNumber();
    const length = BigNumber.from(utils.hexDataSlice(res, offset, offset + 32)).toNumber();
    const data = utils.hexDataSlice(res, offset + 32, offset + 32 + length);
    return utils.toUtf8String(data) || null;
  } catch (e) {
    return null;
  }
}