import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';
import { Trans } from '@lingui/macro';

const NounsLink = () => (
  <Link
    text={<Trans>Nouns</Trans>}
    url="https://nouns.wtf"
    leavesPage={true}
  />
);
const CryptopunksLink = () => (
  <Link
    text={<Trans>Cryptopunks</Trans>}
    url="https://www.larvalabs.com/cryptopunks"
    leavesPage={true}
  />
);
const InversebrahLink = () => (
  <Link
    text={<Trans>@inversebrah</Trans>}
    url="https://twitter.com/inversebrah"
    leavesPage={true}
  />
);
const PlaygroundLink = () => (
  <Link text={<Trans>Playground</Trans>} url="/playground" leavesPage={false} />
);
const RefrigeratorLink = () => (
  <Link text={<Trans>refrigerator</Trans>} url="/playground" leavesPage={false} />
);
const OxigamiLink = () => (
  <Link text={<Trans>0xigami</Trans>} url="https://twitter.com/0xigami" leavesPage={true} />
);
const PublicDomainLink = () => (
  <Link
    text={<Trans>public domain</Trans>}
    url="https://creativecommons.org/publicdomain/zero/1.0/"
    leavesPage={true}
  />
);
const CompoundGovernance = () => (
  <Link
    text={<Trans>Compound Governance</Trans>}
    url="https://compound.finance/governance"
    leavesPage={true}
  />
);

const Documentation = () => {
  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper} id='wtf'>
          <h1>
            <Trans>WTF?</Trans>
          </h1>
          <p className={classes.aboutText}>
            <Trans>
              Tings are an extension of <NounsLink /> attempting to improve funding of crypto twitter derived public goods. We believe cultural utilities such as <InversebrahLink /> are wildly underappreciated.
            </Trans>
          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>
            <Trans>
              Learn more below, or start creating Tings off-chain in the <RefrigeratorLink />.
            </Trans>
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Summary</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <Trans>Tings artwork is in the public domain.</Trans>
                </li>
                <li>
                  <Trans>One Ting is trustlessly auctioned every 2 weeks, forever.</Trans>
                </li>
                <li>
                  <Trans>100% of Ting auction proceeds are trustlessly sent to the treasury.</Trans>
                </li>
                <li>
                  <Trans>Settlement of one auction kicks off the next.</Trans>
                </li>
                <li>
                  <Trans>All Tings are members of Tings DAO.</Trans>
                </li>
                <li>
                  <Trans>Tings DAO uses a fork of Compound Governance.</Trans>
                </li>
                <li>
                  <Trans>One Ting is equal to one vote.</Trans>
                </li>
                <li>
                  <Trans>The treasury is controlled exclusively by TIngs via governance.</Trans>
                </li>
                <li>
                  <Trans>Artwork is generative and stored directly on-chain (not IPFS).</Trans>
                </li>
                <li>
                  <Trans>
                    No explicit rules exist for attribute scarcity; all Tings are equally rare.
                  </Trans>
                </li>
                <li>
                  <Trans>
                    <OxigamiLink /> is rewarded with Tings (10% of supply for first 5 years).
                  </Trans>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Fortnightly Auctions</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                <Trans>
                  The Tings Auction Contract will act as a self-sufficient Ting generation and distribution mechanism, auctioning one Ting every 2 weeks, forever. 100% of auction proceeds (ETH) are automatically deposited in the Tings DAO treasury, where they are governed by Ting owners.
                </Trans>
              </p>

              <p className={classes.aboutText}>
                <Trans>
                  Each time an auction is settled, the settlement transaction will also cause a new Ting to be minted and a new 2 week auction to begin.{' '}
                </Trans>
              </p>
              <p>
                <Trans>
                  While settlement is most heavily incentivized for the winning bidder, it can be triggered by anyone, allowing the system to trustlessly auction Tings as long as Ethereum is operational and there are interested bidders.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Tings DAO</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <Trans>
                Tings DAO utilizes a fork of <CompoundGovernance /> and is the main governing body of the Tings ecosystem. The Tings DAO treasury receives 100% of ETH proceeds from daily Ting auctions. Each Ting is an irrevocable member of Tings DAO and entitled to one vote in all governance matters. Ting votes are non-transferable (if you sell your Ting the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your Ting.
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Governance ‘Slow Start’</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  In addition to the precautions taken by Compound Governance, 0xigami has given themself a special veto right to ensure that no malicious proposals can be passed while the Ting supply is low. This veto right will only be used if an obviously harmful governance proposal has been passed, and is intended as a last resort.
                </Trans>
              </p>
              <p>
                <Trans>
                  0xigami will proveably revoke this veto right when they deem it safe to do so. This decision will be based on a healthy Ting distribution and a community that is engaged in the governance process.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Ting Traits</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  Tings are generated randomly based on Ethereum block hashes. There are no 'if' statements or other rules governing Ting trait scarcity, which makes all Tings equally rare. As of this writing, Tings are made up of:
                </Trans>
              </p>
              <ul>
                <li>
                  <Trans>backgrounds (2) </Trans>
                </li>
                <li>
                  <Trans>bodies (1)</Trans>
                </li>
                <li>
                  <Trans>accessories (132) </Trans>
                </li>
                <li>
                  <Trans>heads (9) </Trans>
                </li>
                <li>
                  <Trans>glasses (16)</Trans>
                </li>
              </ul>
              <Trans>
                You can experiment with off-chain Ting generation at the <PlaygroundLink />.
              </Trans>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>On-Chain Artwork</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  Tings are stored directly on Ethereum and do not utilize pointers to other networks such as IPFS. This is possible because Ting parts are compressed and stored on-chain using a custom run-length encoding (RLE), which is a form of lossless compression.
                </Trans>
              </p>

              <p>
                <Trans>
                  The compressed parts are efficiently converted into a single base64 encoded SVG image on-chain. To accomplish this, each part is decoded into an intermediate format before being converted into a series of SVG rects using batched, on-chain string concatenation. Once the entire SVG has been generated, it is base64 encoded.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>Ting Seeder Contract</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  The Ting Seeder contract is used to determine Ting traits during the minting process. The seeder contract can be replaced to allow for future trait generation algorithm upgrades. Additionally, it can be locked by the Tings DAO to prevent any future updates. Currently, Ting traits are determined using pseudo-random number generation:
                </Trans>
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), nounId))</code>
              <br />
              <br />
              <p>
                <Trans>
                  Trait generation is not truly random. Traits can be predicted when minting a Ting on the pending block.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              <Trans>0xigami’s Reward</Trans>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <Trans>
                  0xigami is the builder that initiated Tings.
                </Trans>
              </p>
              <p>
                <Trans>
                  Because 100% of Ting auction proceeds are sent to Tings DAO, 0xigami has chosen to compensate themself with Tings. Every 10th Ting for the first 5 years of the project (Ting ids #0, #10, #20, #30 and so on) will be automatically sent to 0xigami.eth.
                </Trans>
              </p>
              <p>
                <Trans>
                  0xigami distributions don't interfere with the cadence of 2 week auctions. Tings are sent directly to 0xigami.eth, and auctions continue on schedule with the next available Ting ID.
                </Trans>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
